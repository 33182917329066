import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=3abff770&scoped=true"
import script from "./OrderDetails.vue?vue&type=script&lang=js"
export * from "./OrderDetails.vue?vue&type=script&lang=js"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=3abff770&prod&lang=scss&scoped=true"
import style1 from "./OrderDetails.vue?vue&type=style&index=1&id=3abff770&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3abff770",
  null
  
)

export default component.exports